import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*

class HomeUi : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("container") {
            div("container") {
                div("row") {
                    div("card vcard") {
                        div("") {
                            h4("header center") {
                                +"Florian Hintermeier"
                            }
                            h5("header center") {
                                +"Software Engineering & IT Consulting"
                            }
                        }
                        div("row") {
                            div("col m12 l8") {
                                p("justify") {
                                    +"Working in information technology & software engineering companies and startups, I love building useful apps and platforms to create meaningful solutions. "
                                    +""
                                }
                                p("justify") {
                                    +"I especially like to work in innovative environments with topics like music-tech, psychology, e-learning or machine learning."
                                    +" Having experience as frontend, backend and android developer I like to plan and design the big picture and overall user experience for software products, apps and APIs. "
                                    +""
                                }
                                p("justify") {
                                    +"Despite working as a consultant and freelancer for companies and startups, I offer my services with a social discount for NGOs and social organisations."
                                }
                            }
                            div("col s12 l4 center") {
                                img("img-inside") { attrs.src = "images/foto.jpg" }
                            }
                        }
                    }

                }
            }
            div("container") {
                div("row") {
                    div("col s12 center") {
                        img("img-inside center") {
                            attrs { src = "images/qr-code.png" }
                        }
                    }
                }
            }
        }
    }
}

