import kotlinx.html.unsafe
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*

val services = listOf(
    ServiceOffer(
        "fa-mobile-alt",
        "Android Development (Kotlin/Flutter)",
        listOf(
            "Alone or together with colleagues I/we can plan, design and implement Android apps in modern Kotlin with " +
                    "a clean, robust architecture. Also we offer Flutter development for cross-platform apps (Android+iOS). ",
            "Our approach is mostly User-Centered-Design and to provide fast prototypes, for eager testing and iterative development"
        ),
        listOf(
            "Android Apps with Kotlin & Jetpack",
            "Android/iOS with Flutter/Dart",
            "Robust enterprise apps ...",
            "... or fast serverless prototypes with Firebase"
        )
    ),
    ServiceOffer(
        "fa-server",
        "Backend Development & DevOps <br>(Spring/Kotlin & Express/TypeScript)",
        listOf(
            "With many years of experience as Fullstack and Backend Developer with (too much) Java, Ruby-on-Rails and (thankfully) " +
                    "Kotlin in various environments and companies, I can offer my competences for consulting, domain design, " +
                    "implementation and quality assurance."

        ),
        listOf(
            "Flexible REST Apis with documentation",
            "Robust but expandable architecture ",
            "Clean domain driven design",
            "Infrastructure setup with Docker"
        )
    ),
    ServiceOffer(
        "fa-puzzle-piece",
        "Requirements & Usability Engineering",
        listOf(
            "As certified CPRE and CPUX I can help organisations to understand the actual needs and requirements" +
                    " to establish a plan and concept for possible solutions.",
            "With help of interaction & web designers, we can build prototypes, concepts and run user studies and" +
                    " iterations to maximize the user experience of your solutions."
        )
    ),
    ServiceOffer(
        "fa-lightbulb",
        "Consulting & Innovation",
        listOf(
            "Sometimes a project can start with a tendency of having a biased idea of its outcome. Sometimes one might " +
                    "say `we need an app for that`, when a webplatform or just an existing solution would fit better " +
                    "than a complex, expensive custom project.",
            " I like to help organisations like NGOs or small companies to understand the actual requirements" +
                    " and financial possibilities of IT solutions and to find the right fit finally."
        )
    )

)

class ServicesUi : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("container") {
            div("section no-pad-bot") {
                div("container") {
                    h2("header center") {
                        +"Services & Freelancing"
                    }
                }
            }
            div("section") {
                for (row in 0..1) {
                    div("row") {
                        for (item in 0..1) {
                            val current = services[row * 2 + item]
                            div("col s12 m6 l6") {
                                div("padded") {
                                    div("icon-block") {
                                        h2("center") {
                                            i("large fas ${current.icon}") {}
                                        }
                                    }
                                    h5("center") { attrs.unsafe { +current.title } }
                                    div("justify") {
                                        current.texts.forEach {
                                            p("light block justify") { attrs.unsafe { +it } }
                                        }
                                        if (current.items.isNotEmpty()) {
                                            ul("collection") {
                                                current.items.forEach {
                                                    li("collection-item") { attrs.unsafe { +it } }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

