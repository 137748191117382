import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*

class RecruitersUi : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("container") {
            div("container") {
                div("row") {
                    div("card vcard") {
                        div("") {
                            h4("") {
                                +"Hello, "
                            }
                            h5("") {
                                +"thank you for reaching out to me!"
                            }
                        }
                        div("row") {
                            div("col l12") {
                                p("justify") {
                                    +"For me it is really important what I do and who I work for. For example, I like to work in innovative environments with topics like music-tech, psychology, e-learning or machine learning. "
                                    +"It is quite understandable, that as a recruiter it is important to keep your client's name confidential, but I have to know if a particular offer matches my interests and values. "
                                    +""
                                    +"Therefore in order to ease your work I want to share with you what is important to me professionally:"
                                    ul("normal-li") {
                                        li { +"Working in an innovative, interdisciplinary or academic environment" }
                                        li { +"Working for a social impact or organisations which improve sustainable development goals" }
                                        li { +"Working together with persons who value and establish an environment for learning, self-improvement and quality" }
                                        li { +"Working with a modern, scalable, robust tech stack which is fun to work with" }
                                    }

                                }
                                p("justify") {
                                    +"And there are some aspects I happen to experience as difficult to work with:"
                                    ul("normal-li") {
                                        li { +"Companies which act in sectors of gambling, weapons or similar" }
                                        li { +"Projects which rely on old legacy systems without any tests or QA and not enough will or resources to fix that" }
                                        li { +"A tech stack which has a vendor-lockin, e.g. requiring an IBM WebSphere, and an IBM engineer because of ... reasons." }
                                        li { +"Having no project management process at all. No QA, no ownership, no responsibility" }
                                    }
                                }
                                p("justify") {
                                    +"So please, when contacting me via linkedin, help me gain interest in your offer:"
                                    ul("normal-li") {
                                        li { +"What is the organisation actually doing?" }
                                        li { +"Who are my colleagues and what is my environment?" }
                                        li { +"How does the team ensure sustainability while still striving for success?" }
                                        li { +"How does the organisation establish team collaboration, project management, quality and continuous improvement?" }
                                    }
                                    +"Thank you for your patience, "
                                    a(
                                        href = "https://www.linkedin.com/in/florian-hintermeier-16350a133/",
                                        target = "_blank"
                                    ) { +"I welcome your message!" }
                                }
                                p("justify") {
                                    +"Additionally to working as a consultant and freelancer for companies and startups, I offer my services with a social discount for NGOs and social organisations."
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

