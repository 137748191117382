import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*

private val projects = listOf(
    Project(
        "MLReef Machine Learning Ops Platform",
        "mlreef.png",
        "https://gitlab.com/mlreef/mlreef",
        listOf(
            "As the Backend Team Lead I was in charge of the architecture, domain design and the general concept and implementation of our REST API. " +
                    "Together with a highly-motivated team we built a platform with Spring stack, Kotlin and React as frameworks, Gitlab and PostgreSQL as service providers. " +
                    "We work entirely with Docker, Gitlab CI and AWS for our DevOps and infrastructure, which goes pretty well with us being fully remote."

        ),
        listOf(TechTag.Kotlin, TechTag.Spring, TechTag.ApiDesign, TechTag.React, TechTag.Docker)
    ),
    Project(
        "KnowledgeFox Android App",
        "KnowledgeFox_logo_PRINT-07.png",
        "https://www.knowledgefox.net",
        listOf(
            "As the Android Team lead, I modernised the Android eLearning app with a modern Material design and UX and refactored to Kotlin, Jetpack and clean architecture. ",
            "In my time as an employee, we designed many new features and refactored the whole tech stack to use Retrofit, Jetpack and LiveData to improve the overall experience. "
                    + "Finally, I added lots of tests (GUI, Unit & Integration) and rebuilt the integration and CI pipeline"
        ),
        listOf(TechTag.Kotlin, TechTag.Android, TechTag.Java)
    ),
    Project(
        "Tokko Clarinet Android App",
        "tokko_logo_medium_2.png",
        "https://play.google.com/store/apps/details?id=com.tokkomusic.clarinet1_lite&gl=AT",
        listOf("My master thesis project led to a prototype for an Android app which supports people learning the clarinet at home. " +
                "We conducted many user research studies, interviews, carried out interaction design and worked iteratively on the Android app. ",
        "I had the opportunity to spend a lot of time programming the music and sound analysis (native code) for real-time pitch-detection and a fast and delightful user experience."),
        listOf(TechTag.Android, TechTag.Kotlin, TechTag.UX, TechTag.Concept, TechTag.Research)
    ),
    Project(
        "OpenSheetMusic Display",
        "OSMD_3_icon_only.svg",
        "https://opensheetmusicdisplay.org/",
        listOf(
            "I contributed to the open source project 'Open Sheet Music Education' to render sheet music in iframes. ",
            "After working on Tokko, I looked for cooperations and found the great OSMD library, built on vexflow, for sheet music rendering in browser." +
                    " Further plans include the embedding in browsers and mobile applications."
        ),
        listOf(TechTag.TypeScript, TechTag.JavaScript)
    ),
    Project(
        "OSME Sheet Generator",
        "osme-logo-340-v3.png",
        "https://opensheetmusiceducation.org/generate-sight-reading-practice/",
        listOf(
            "After contributing to OSMD, I implemented the OSM Education 'Sight reading generator', " +
                    " funded by Netidee and developed via PhonicScore. We chose to implement it in TypeScript to use it in frontends and backends for all possibilities"
        ),
        listOf(TechTag.TypeScript, TechTag.ExpressJS)
    )

)

class ProjectsUi : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("container") {
            div("section no-pad-bot") {
                div("container") {
                    h2("header center") {
                        +"Projects"
                    }
                    p("center") {
                        +"A selected showcase of projects I contributed to as a freelancer, as an employee, for open source or for fun."
                    }
                }
            }
            div("section") {
                div("row") {
                    projects.forEach {
                        div("col s12 m12 l6") {
                            div("card card-height") {
                                div("card-content") {
                                    span("center card-title grey-text text-darken-4 activator") {
                                        a(href = it.linkUrl, target = "_blank") {
                                            img(classes = "center-img") { attrs.src = "images/" + it.imageUrl }
                                            +it.title
                                        }
                                        div { it.tags.forEach { techTag(it) } }

                                    }
                                    it.longText.forEach {
                                        p("justify") { +it }
                                        br { }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
