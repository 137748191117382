import kotlinx.html.*
import pages.CvUi
import react.*
import react.dom.*
import react.router.dom.hashRouter
import react.router.dom.route
import react.router.dom.routeLink
import react.router.dom.switch
import kotlin.reflect.KClass

data class Link(
    val url: String,
    val title: String,
    val clazz: KClass<out Component<RProps, *>>,
    val public: Boolean = true
)

val links = listOf(
    Link("/", "About", HomeUi::class),
    Link("/cv", "CV", CvUi::class),
    Link("/projects", "Projects", ProjectsUi::class),
    Link("/services", "Services", ServicesUi::class),
    Link("/recruiters", "Recruiters", RecruitersUi::class, public = false)
)

fun RDOMBuilder<DIV>.techTag(tag: TechTag) {
    div("chip") {
        attrs["dataBadgeCaption"] = tag.name
        +tag.name
    }
}

class App : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        hashRouter {
            body {
                nav("") {
                    attrs { role = "navigation" }
                    div("nav-wrapper container") {
                        ul("right") {
                            links.filter { it.public }.forEach { link ->
                                li { routeLink(link.url) { +link.title } }
                            }
                        }
                    }
                }
                switch {
                    links.forEach { link ->
                        route(link.url, link.clazz, exact = true)
                    }
                }
            }
        }
    }

    private fun RDOMBuilder<DIV>.menuLogo() {
        a("brand-logo") {
            attrs {
                id = "logo-container"
                href = "#"
            }
            +"About"
        }
    }

    inline fun RBuilder.htmlMain(classes: String? = null, block: RDOMBuilder<MAIN>.() -> Unit): ReactElement =
        tag(block) {
            MAIN(
                attributesMapOf("class", classes), it
            )
        }
}
