package domain

import TechTag
import pages.PositionEntryType
import kotlin.js.Date

data class PositionEntry(
    val begin: Date,
    val end: Date?,
    val title: String,
    val subtitle: String,
    val techTags: List<TechTag> = arrayListOf(),
    val infos: List<String> = arrayListOf(),
    val type:PositionEntryType = PositionEntryType.JOB
)
