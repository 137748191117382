package pages

import TechTag
import domain.PositionEntry
import react.RBuilder
import react.RComponent
import react.RProps
import react.RState
import react.dom.*
import techTag
import kotlin.js.Date

val months = mapOf(
    1 to "January",
    2 to "February",
    3 to "March",
    4 to "April",
    5 to "May",
    6 to "June",
    7 to "July",
    8 to "August",
    9 to "September",
    10 to "October",
    11 to "November",
    12 to "December"
)

enum class PositionEntryType {
    JOB,
    STUDY,
    CERTIFICATE
}

private val entries = listOf(
    PositionEntry(
        begin = Date(2020, 10),
        end = null,
        title = "MSc Innovation & Technology Management",
        subtitle = "UAS Technikum Vienna",
        techTags = listOf(),
        infos = listOf(
            "Methods of innovation processes & Design Thinking",
            "project & change management and entrepreneurship",
            "fundamentals of funding, company laws and finance"
        ),
        type = PositionEntryType.STUDY
    ),
    PositionEntry(
        begin = Date(2020, 5),
        end = null,
        title = " Certified Professional for Requirements Engineering, Foundation Level (CPRE-FL)",
        subtitle = "IREB",
        techTags = listOf(),
        infos = listOf(),
        type = PositionEntryType.CERTIFICATE
    ),
    PositionEntry(
        begin = Date(2019, 10),
        end = null,
        title = "MLReef GmbH",
        subtitle = "Senior Backend Developer",
        techTags = listOf(TechTag.Kotlin, TechTag.Spring, TechTag.Concept, TechTag.Docker, TechTag.Linux),
        infos = listOf(
            "Building a collaborative platform for data scientist to run and improve their machine learning projects",
            "Software architecture and domain driven design for Spring Boot",
            "Defining and gathering service requirements",
            "Kotlin, Spring stack and lots of tests for our machine learning collaboration platform"
        )
    ),
    PositionEntry(
        begin = Date(2015, 1),
        end = null,
        title = "Self-Employed & Independent",
        subtitle = "Software Engineering & IT Consulting",
        techTags = listOf(
            TechTag.Kotlin,
            TechTag.Spring,
            TechTag.Android,
            TechTag.RubyOnRails,
            TechTag.UX,
            TechTag.Concept,
            TechTag.Java
        ),
        infos = listOf(
            "Concept & implementation of REST APIs, Apps and web platforms",
            "Gathering business and technical requirements",
            "Developing and improving Android apps",
            "Developing and improving web platforms with Ruby On Rails or Spring Boot",
            "Project consulting and ideation process consulting",
            "Support innovation and project management"
        )
    ),
    PositionEntry(
        begin = Date(2018, 3),
        end = Date(2019, 10),
        title = "KnowledgeFox GmbH",
        subtitle = "Android Developer",
        techTags = listOf(
            TechTag.Kotlin, TechTag.Java, TechTag.Android, TechTag.Concept,
            TechTag.UX, TechTag.Reactive
        ),
        infos = listOf(
            "Developing the new Android app for #1 Austrian microlearning & elearning cmopany",
            "Designed and implemented user onboarding flow and improved overall user experience",
            "Refactor legacy code base to use Material Design, Retrofit and Android Jetpack",
            "Introduced Kotlin and refactored a lot of legacy models",
            "Introduced IoC, better reactive architecture and concurrency",
            "Improved continuous integration, testing coverage and introduced instrumentation tests"
        )
    ),
    PositionEntry(
        begin = Date(2017, 1),
        end = Date(2018, 1),
        title = "alysis GmbH",
        subtitle = "Full-Stack Developer",
        techTags = listOf(TechTag.Java, TechTag.Spring, TechTag.JHipster, TechTag.Angular, TechTag.JavaScript),
        infos = listOf(
            "Full-stack software engineering with JHipster, Spring MCV and AngularJs",
            "Backend development with Spring MVC and REST API",
            "Helping out with Design Thinking and Usability processes"
        )
    ),
    PositionEntry(
        begin = Date(2016, 10),
        end = Date(2018, 11),
        title = "MSc Software Development",
        subtitle = "UAS Technikum Vienna",
        techTags = listOf(),
        infos = listOf(
            "Focus on User-Centered Design, usability and research",
            "Working with Kotlin and Android for my thesis",
            "Diving into e-learning and music-tech programming"
        ),
        type = PositionEntryType.STUDY
    ),
    PositionEntry(
        begin = Date(2015, 2),
        end = Date(2017, 1),
        title = "Backend Developer",
        subtitle = "Government data and applications",
        techTags = listOf(TechTag.Java, TechTag.Spring, TechTag.JHipster, TechTag.Angular, TechTag.JavaScript),
        infos = listOf(
            "Development and improvement of E-Gov applications with Java and SQL",
            "Implementation of high performant SQL queries and batch execution for statistic",
            "Aggregation and computation of data and Excel/PDF conversion"
        )
    ),
    PositionEntry(
        begin = Date(2015, 5),
        end = null,
        title = "Certified Professional for Usability and User Experience - Foundation Level (CPUX-F)",
        subtitle = "UXQB",
        techTags = listOf(),
        infos = listOf(),
        type = PositionEntryType.CERTIFICATE
    ),
    PositionEntry(
        begin = Date(2013, 6),
        end = Date(2015, 1),
        title = "Software & Systems Engineer",
        subtitle = "Developing & Linux scripting",
        techTags = listOf(TechTag.Java, TechTag.Spring, TechTag.JHipster, TechTag.Angular, TechTag.JavaScript),
        infos = listOf(
            "Linux, DevOps, and lots of bash scripting",
            "Ruby on Rails & Java/EE with GWT",
            "Services configuration like Postfix, CAS and LDAP"
        )
    ),
    PositionEntry(
        begin = Date(2010, 10),
        end = Date(2015, 11),
        title = "BSc Software & Information Engineering",
        subtitle = "TU Wien (Vienna University of Technology)",
        techTags = listOf(),
        infos = listOf(
            "Focus on software engineering & project management",
            "Creative methods and teamwork"
        ),
        type = PositionEntryType.STUDY
    )
)


class CvUi : RComponent<RProps, RState>() {
    override fun RBuilder.render() {
        div("container") {
            div("section no-pad-bot") {
                div("container") {
                    h2("header center") {
                        +"CV"
                    }
                }
            }
            div("section") {
                div("row") {
                    entries.forEach {
                        div("col m4") {
                            h5 {
                                if (it.end == null) {
                                    +"Since ${it.begin.getFullYear()}-${months[it.begin.getMonth()]}"
                                } else {
                                    +"${it.begin.getFullYear()}-${months[it.begin.getMonth()]} - ${it.end.getFullYear()}-${months[it.end.getMonth()]}"
                                }
                            }
                        }
                        div("col m8") {
                            div("card") {
                                div("card-content") {
                                    p { b { +"${it.title}" } }
                                    i { +"${it.subtitle}" }
                                    if (it.type == PositionEntryType.STUDY) {
                                        div("icon-block right") {
                                            i("large fas fa-university") {}
                                        }
                                    }
                                }
                                if (it.infos.isNotEmpty() || it.techTags.isNotEmpty()) {
                                    div("card-content grey lighten-4") {
                                        div { it.techTags.forEach { techTag(it) } }
                                        ul("browser-default") {
                                            it.infos.forEach {
                                                li { +"$it" }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
