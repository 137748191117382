enum class TechTag() {
    Kotlin,
    Java,
    Spring,
    SQL,
    ApiDesign,
    Android,
    Concept,
    ReqEngineering,
    ProjectManagement,
    UX,
    Reactive,
    JHipster,
    RubyOnRails,
    TypeScript,
    ExpressJS,
    JavaScript,
    Angular,
    React,
    Linux,
    Docker,
    Research
}